.form-floating {
  position: relative;
  &.sticky_label {
    .form-control {
      height: $form-floating-height;
      min-height: $form-floating-height;
      line-height: $form-floating-line-height;
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    label {
      transform: $form-floating-label-transform;
      &::after {
        position: absolute;
        inset: $form-floating-padding-y ($form-floating-padding-x * .5);
        z-index: -1;
        height: $form-floating-label-height;
        content: "";
        background-color: $input-bg;
        @include border-radius($input-border-radius);
      }
    }
  }
  > .form-control,
  > .form-control-plaintext,
  > .form-select {
    height: $form-floating-height;
    min-height: $form-floating-height;
    line-height: $form-floating-line-height;
  }
  textarea {
    &.form-control {
      min-height: $input-height;
      height: auto;
    }
  
    &.form-control-sm {
      min-height: $input-height-sm;
      height: auto;
    }
  
    &.form-control-lg {
      min-height: $input-height-lg;
      height: auto;
    }
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%; // allow textareas
    padding: $form-floating-padding-y $form-floating-padding-x;
    color: rgba(var(--#{$prefix}body-color-rgb), #{$form-floating-label-opacity});
    margin: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    @include transition($form-floating-transition);
  }

  > .form-control,
  > .form-control-plaintext {
    padding: $form-floating-padding-y $form-floating-padding-x;
    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }

  > .form-select {
    padding: 0;
    border: 0;
    [class*="control"] {
      box-shadow: none;
      height: 100%;
      @include border-radius($input-border-radius);
      >div{
        max-height: 2.7rem;
        overflow-y: auto;
      }
      // [class*="ValueContainer"] {
        [class*="multiValue"] {
          &:first-child {
            margin-top: 0.7rem;
          }
        }
      // }
    }
    [class*="control"]:hover, [class*="control"]:focus {
      border-color: $primary !important;
      box-shadow: none !important;
    }
    [class*="menu"] {
      z-index: 9;
    }
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      color: rgba(var(--#{$prefix}body-color-rgb), #{$form-floating-label-opacity});
      transform: $form-floating-label-transform;
      &::after {
        position: absolute;
        inset: $form-floating-padding-y ($form-floating-padding-x * .5);
        z-index: -1;
        height: $form-floating-label-height;
        content: "";
        background-color: $input-bg;
        @include border-radius($input-border-radius);
      }
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      color: rgba(var(--#{$prefix}body-color-rgb), #{$form-floating-label-opacity});
      transform: $form-floating-label-transform;
    }
  }

  > .form-control-plaintext {
    ~ label {
      border-width: $input-border-width 0; // Required to properly position label text - as explained above
    }
  }

  > :disabled ~ label {
    color: $form-floating-label-disabled-color;

    &::after {
      background-color: $input-disabled-bg;
    }
  }
}

.form-row {
  > .col, >[class*=col-] {
    &.form-floating {
      > label {
      left: calc(0px + $card-group-margin);
      }
      > .form-control:focus,
      > .form-control:not(:placeholder-shown),
      > .form-control-plaintext,
      > .form-select {
        ~ label {
          transform: $form-floating-label-transform, $card-group-margin;
        }
      }
      > .form-control:-webkit-autofill {
        ~ label {
          transform: $form-floating-label-transform, $card-group-margin;
        }
      }
    }
  }
}