.custom-option {
    padding: 8px 12px;
    border-bottom: 1px solid #ddd;
  }
  
  .custom-option .lead-no {
    font-weight: bold;
    color: #007bff;
  }
  
  .custom-option .contact-person {
    font-size: 14px;
    color: #333;
  }
  
  .custom-option .business-name {
    font-size: 12px;
    color: #777;
  }
  
  .custom-option:hover {
    background-color: #f8f9fa;
  }
  