//--------------------------Theme--01--------------------------//
.login-page {
  background: lighten($secondary, 3%);
  width: 100%;
  height: 100vh;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  > .row {
    width: 100%;
    height: 100%;
    background: lighten($secondary, 3%);
    box-shadow: 0px 0px 0.5rem 0px rgb(0 0 0 / 15%);
    border-radius: 0;
    padding: 0;
    padding-right: 0 !important;    
    .login-img {
      padding: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      gap: 1rem;
      height: 100%;
      background: lighten($secondary, 3%);
      flex: 1 0;
      width: 100%;
      .auth-info {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0;
        h2 {
          color: $text-light;      
          font-size: calc($font-size-lg * 2);
          padding: 35vh 8vw 3vw 8vw;
          font-weight: bold;
          line-height: 1.5;
          background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) -10%, hsl(210deg 6% 7% / 90%) 100%);
          margin: 0;
          span {
            color: $primary;
          }
        }
      }
      img {
        object-fit: cover;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 0.9rem;
        border: 1px solid rgb(255 255 255 / 50%);
      }
      .image-track {
        display: flex;
        width: calc(16.6vw * 4); /* Adjust based on number of images */
        max-width: 16.6vw;
        flex-direction: column;
        gap: 1rem;
        animation: auth-scroll 30s linear infinite alternate;
        &::after {
          content: '';
          display: flex;
          width: calc(16.6vw - 0.5rem);
          background-size: 16.6vw;
          background-repeat: no-repeat;
          animation: auth-scroll 30s linear infinite alternate;
          aspect-ratio: 1 / 4;
          height: 25%;
          position: absolute;
          top: calc(100% + 0.5rem);
          left: 0;
          border-radius: 0.9rem;
        }
        &.track-one {
          &::after {
            background: url(../assets/images/auth-slider/imge_01.webp), url(../assets/images/auth-slider/imge_02.webp), url(../assets/images/auth-slider/imge_03.webp), url(../assets/images/auth-slider/imge_04.webp);
          }
        }
        &.track-two {
          animation: auth-revers-scroll 30s linear infinite alternate;
          &::after {
            background: url(../assets/images/auth-slider/imge_06.webp), url(../assets/images/auth-slider/imge_07.webp);
            top: auto;
            bottom: calc(-20% + 2rem);
          }
        }
        &.track-three {
          &::after {
            background: url(../assets/images/auth-slider/imge_09.webp), url(../assets/images/auth-slider/imge_10.webp);
          }
        }
      }
    }
    .login-main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 5vh 0;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.3) -9%, rgba(255, 255, 255, 0) 14%);
      background-size: 2rem;
      &::before {
        content: "";
        background: url(../assets/images/auth_left.webp) no-repeat;
        width: 50%;
        height: 70%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.3;
        z-index: 0;
        transition: all 0.3s ease;
      }
      &::after {
        content: "";
        background: url(../assets/images/auth-right.webp) no-repeat;
        width: 50%;
        height: 70%;
        position: absolute;
        right: -10%;
        top: 0;
        opacity: 0.3;
        transition: all 0.3s ease;
        z-index: 0;
      }
      .loging-head {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        img {
          max-height: 5.5rem;
        }
      }
      .loging-content {
        width: 90%;
        height: auto;
        text-align: left;
        z-index: 1;
        position: relative;
        h2 {
          max-width: 35vw;
          margin: 0 auto 0.5rem;
          padding: 0 1rem;
          width: 100%;
          font-size: calc($font-size-lg * 1.8);
          font-weight: bold;
          letter-spacing: 0.5px;
          color: $text-light;
          text-align: start;
          text-transform: capitalize;
        }
        p {
          max-width: 35vw;
          margin: 0 auto 1.5rem;
          padding: 0 1rem;
          font-weight: normal;
          width: 100%;
          font-size: calc($font-size-base * 1.1);
          color: $text-light;
          line-height: inherit;
          text-align: start;
        }
        button {
          border-radius: .5rem;
          padding-block: 0.8rem;
          font-weight: bold;
        }
        .btn-link {
          text-decoration: none;
          &:hover, &:focus {
            color: $info;
            text-decoration: underline;
          }
        }
        .back-signin {
          text-transform: capitalize;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          color: $text-light;
          &:is(:hover, :focus) {
            color: $primary;
          }
        }
      }
    }
  }
  
  &.user_login{
    position: relative;
    background: #fff;
    &::after{
      content: "";
      background: url(../assets/images/signin_bg.jpg);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.15;
    }
    > .row{
      width: 63vw;
      height: 75vh;
      background: #fff;
      box-shadow: 0 0 2.3rem rgb(0 0 0 / 14%);
      border-radius: 28px;
      padding: 0;
      padding-right: 0 !important;   
      justify-content: end;
      z-index: 1;
      .login-img{
        position: relative;
        background: rgba($primary, 0.7);
        &::after{
          content: "";
          background: url(../assets/images/bg_vector.png);
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          bottom: -15rem;
          left: -10rem;
          width: 100%;
          height: 100%;
        }
        .login_img_text{
          padding: 1.8rem;
          position: relative;
          z-index: 1;
          background: #ffffff38;
          backdrop-filter: blur(5px);
          height: 85%;
          width: 85%;
          border-radius: 20px;
          // img{
          //   object-fit: contain;
          //   position: absolute;
          //   left: -1rem;
          //   bottom: -5.5rem;
          //   width: 106%;
          // }
          h2{
            font-weight: 600;
            span{
              -webkit-text-stroke: 1px black;
              color: transparent;
              letter-spacing: 1px;
            }
          }
          .side_img_box{
            .file_img{
              object-fit: contain;
              width: 106%;
              height: 14rem;
              position: absolute;
              left: -0.9rem;
              bottom: 7.8rem;
            }
            .charecter_img{
              object-fit: contain;
              width: 100%;
              height: 17.3rem;
              position: absolute;
              bottom: -2rem;
              left: -1rem;
              -webkit-animation: slide-top 5s ease-in-out infinite alternate-reverse both;
	            animation: slide-top 5s ease-in-out infinite alternate-reverse both;
            }
          }
        }
      }
      .login-form{
        .login-main{
          .loging-content{
            h2{
              color: $black;
            }
          }
        }
        .pass_view_btn{
          position: absolute;
          right: 1.4rem;
          top: 50%;
          transform: translateY(-50%);
          padding: 0;
          color: $black;
          &:hover,&:focus{
            color: $primary !important;
          }
        }
      } 
    }
    &.user_signup{
      > .row{
        width: 75vw;
        height: 80vh;
        .loging-content{
          width: 100%;
        }
        .form-login{
          .form-group{
            width: 50%;
          }
        }
      }
    }
  }
  .login-form {
    height: 100%;
    padding: 0;
    .form-login {
      max-width: 35vw;
      display: block;
      margin: 1rem auto;
      .form-group {
        display: inline-block;
        position: relative;
        width: 100%;
        .form-label {
          color: $text-light;
          text-transform: capitalize;
        }
        .form-control {
          background: #343637;
          color: $white;
          border-color: #393b3b;
          min-height: calc($input-height * 1.2);
          &::placeholder {
            color: rgba($white, 0.6);
          }
        }
        .srv-validation-message {
          font-size: 0.75rem;
        }
      }
      .verify_code_input{
        .react-code-input{
          display: flex !important;
          justify-content: space-between;
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0; 
          }
          input[type=number]{
            font-size: 1.5rem !important;
            caret-color: #00000066;
            outline: none;
            text-align: center;
            padding: 0 !important;
            &:focus {
              border-color: $primary !important;
            }
          }
        }
      }
      // .form-label{
      //   &:required,&:invalid { box-shadow:none; }
      // }
      .custom-control.custom-checkbox {
        margin: 0;
        display: flex;
        align-items: center;
        .custom-control-label {
          line-height: 1.7;
        }
      }
      .forgot_password {
        color: #344767;
        &:hover, &:focus {
          color: #673ab7;
          text-decoration: underline;
        }
      }
      .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none !important;
      }
      .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #383739;
      }
      .btn-primary {
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: 5px;
        outline: none;
        box-shadow: none;
        &:hover,&:focus {
          opacity: 0.8;
        }
      }
      .backto_login {
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 0.9rem;
        }
      }
    }
    .copy-right {
      padding-top: 0.4rem;      
      p {
        color: $text-light;
        margin: 0;        
      }
    }
  }       
}

@keyframes auth-scroll {
  0% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(-30%);
  }
}


@keyframes auth-revers-scroll {
  0% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(5%);
  }
}

/**
 * ----------------------------------------
 * animation slide-top for sign in page
 * ----------------------------------------
 */
 @-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}


@media screen and (max-width:1455px) {
  .login-page{
    &.theme_01{
      &.user_login{
        > .row{
          height: 84vh;
          .login-img{
            .login_img_text{
              padding: 1.4rem;
            }
          }
        }
        &.user_signup{
          > .row{
            width: 78vw;
            height: 87vh;
          }
        }
      }
    }
  } 
}
@media screen and (max-width:1280px) {
  .login-page{
    &.user_login{
      > .row{
        .login-img{
          .login_img_text{
            padding: 1.3rem;
            h2{
              font-size: 1.4rem;
            }
          }
        }
        .login-main{
          .loging-content{
            h2{
              font-size: 1.3rem;
            }
          }
        }
        .login-form{
          .form-login{
            .form-group{
              .form-control{
                height: 2.6rem;
              }
            }
          }
        }
      }
      &.user_signup{
        > .row{
          width: 78vw;
          height: 87vh;
        }
      }
    }
  } 
}
@media screen and (max-width:991px) {
  .login-page {
    > .row {
      .login-form {
        .login-main {
          .loging-content {
            h2, p {
              max-width: 50vw;
            }
          }
          .form-login {
            max-width: 50vw;
          }
        }
      }
      .login-img {
        display: none;
      }
    }
  }
}
@media screen and (max-width:767px) {
  .login-page {
    > .row {
      .login-form {
        .login-main {
          .loging-content {
            h2, p {
              max-width: 75vw;
            }
          }
          .form-login {
            max-width: 75vw;
          }
        }
      }
    }
  }
}
@media screen and (max-width:575px) {
  .login-page {
    > .row {
      .login-form {
        .login-main {
          .loging-content {
            h2, p {
              max-width: 95vw;
            }
          }
          .form-login {
            max-width: 95vw;
          }
        }
      }
    }
  }
}
