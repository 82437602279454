body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pac-container {
  z-index: 99999999999 !important;
}

/* THE LOADING EFFECT */
.skeleton {
  background-color: #e2e5e7;
  /* Base color for the skeleton placeholder */

  /* The shine effect moving across the skeleton */
  background-image:
    linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      /* Transparent */
      rgba(255, 255, 255, 0.5),
      /* Semi-transparent white */
      rgba(255, 255, 255, 0)
      /* Transparent */
    );

  background-size: 40px 100%;
  /* Width of the shine */
  background-repeat: no-repeat;
  /* Prevent repeating the shine effect */
  background-position: -40px 0;
  /* Start position of the shine effect, offset by the width of the shine */

  animation: shine .51s ease infinite;
  /* Animate the shine effect */
}

/* Keyframes for the shine animation */
@keyframes shine {
  to {
    /* Move the shine effect from left to right */
    background-position: calc(100% + 40px) 0;
  }
}

.custom-size-modal-table {
  width: 1200px !important;
  display: flex;
  justify-content: center;
  max-width: 3000px !important;
}